'use client'

import { Box, Typography, Container } from '@mui/material'
import { styled } from '@mui/material/styles'
import Image from 'next/image'

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100dvh',
  backgroundColor: theme.palette.background.default,
}))

const StyledImage = styled(Image)({
  marginBottom: '2rem',
})

export default function NotFound() {
  return (
    <Container maxWidth="sm">
      <StyledBox>
        <StyledImage
          src="/page_not_found.svg"
          alt="ページが見つかりません"
          width={300}
          height={300}
          priority
        />
        <Typography variant="h4" component="h1" gutterBottom>
          ページが見つかりません
        </Typography>
        <Typography variant="body1" color="text.secondary">
          お探しのページは存在しないか、移動した可能性があります。
        </Typography>
      </StyledBox>
    </Container>
  )
}
